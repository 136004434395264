import { Box, Link, Typography } from "@mui/material";
import { Button, toast } from "aagent-ui";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { formatUnixTimestamp } from "./time_util";
import * as types from "./types";
import * as statusUtils from "./statusUtils";
import { fetchAPI } from "./authenticator_util";
import { SyntheticEvent, useState } from "react";
import { formatReimbursementAmount } from "./ContractUtils";

function getDisabled(status: types.StatusType) {
  return (
    status === types.StatusType.WORK_IN_PROGRESS ||
    status === types.StatusType.GIVE_UP
  );
}

function getButtonColor(status: types.StatusType) {
  if (status === types.StatusType.APPROVED) {
    return "success";
  }

  return "primary";
}

function getButtonText(status: types.StatusType) {
  if (status === types.StatusType.WORK_IN_PROGRESS) {
    return "Pending";
  }

  if (status === types.StatusType.APPROVED) {
    return "Approved";
  }

  if (status === types.StatusType.GIVE_UP) {
    return "Abandoned";
  }

  return "Approve";
}

export default function ContractItem({
  contract,
}: {
  contract: types.Contract;
}) {
  const [status, setStatus] = useState<types.StatusType>(
    statusUtils.getLatestStatus(contract.statuses)
  );
  const navigate = useNavigate();
  const handleApprove = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (status !== types.StatusType.WAIT_APPROVAL) {
      return;
    }

    fetchAPI(`/contract/${contract.id}/approve`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
      .then(() => setStatus(types.StatusType.APPROVED))
      .then(() => toast.success("Contract approved"))
      .catch((err) => toast.error(String(err)));
  };

  return (
    <Box
      onClick={() => navigate(`/contract/${contract.id}`)}
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid rgba(238, 234, 248, 0.75)",
        boxShadow: "0px 5px 40px -11.4039px rgba(229, 233, 242, 0.3)",
        borderRadius: "20px",
        display: "block",
        padding: "16px",
        my: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h2" sx={{ color: "secondary.main" }}>
            {formatReimbursementAmount(contract)}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "grey.400" }}>
            {formatUnixTimestamp(contract.last_updated)}
          </Typography>
        </Box>

        <Button
          variant={
            status === types.StatusType.WORK_IN_PROGRESS
              ? "contained"
              : "outlined"
          }
          disabled={getDisabled(status)}
          color={getButtonColor(status)}
          sx={{ borderRadius: "10px", padding: "9px 17px" }}
          onClick={handleApprove}
        >
          <Typography
            variant="h5"
            sx={{ lineHeight: "normal", fontWeight: "bold" }}
          >
            {getButtonText(status)}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
