import {
  Box,
  Card,
  CardContent,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchAPI } from "./authenticator_util";
import { Plan } from "./types";
import ContractItem from "./ContractItem";

export default function PlanContracts({ plan }: { plan: Plan }) {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState<any[]>([]);

  useEffect(() => {
    fetchAPI(`/plan/${plan.id}/contracts`)
      .then((response) => response.json())
      .then((contracts) => {
        setContracts(contracts);
        setLoading(false);
      });
  }, [plan.id]);

  return (
    <Container sx={{ maxWidth: { xs: "600px" } }}>
      {loading && <LinearProgress />}
      <Box>
        {!loading && contracts.length === 0 && (
          <Card>
            <CardContent>
              <Typography>
                Currently you don't have any contracts under this plan yet.
              </Typography>
            </CardContent>
          </Card>
        )}

        {contracts.length > 0 &&
          contracts.map((contract) => (
            <ContractItem key={contract.id} contract={contract} />
          ))}
      </Box>
    </Container>
  );
}
