import * as types from "./types";

export function getLatestStatus(statuses: types.Status[]): types.StatusType {
  if (!statuses || statuses.length == 0) {
    return types.StatusType.WORK_IN_PROGRESS;
  }

  return statuses[statuses.length - 1].status_type;
}

export function isLatestCompleted(statuses: types.Status[]): boolean {
  return getLatestStatus(statuses) !== types.StatusType.WORK_IN_PROGRESS;
}
