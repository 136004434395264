import { Typography, TextField } from "@mui/material";
import { Button } from "aagent-ui";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";

export default function Dispute() {
  return (
    <CardWithBottomNavLayout>
      <Typography
        variant="h4"
        color="secondary.main"
        sx={{ marginBottom: "43px" }}
      >
        DISPUTE CONTRACT
      </Typography>

      <TextField
        variant="outlined"
        fullWidth
        label="Reason"
        size="medium"
        margin="normal"
        multiline
        rows={4}
        // value={user.attributes.phone_number}
      />
      <TextField
        variant="outlined"
        fullWidth
        label="Action Required"
        size="medium"
        margin="normal"
        multiline
        rows={6}
        // value={user.attributes.phone_number}
      />
      <Button variant="contained" fullWidth>
        Submit
      </Button>
    </CardWithBottomNavLayout>
  );
}
