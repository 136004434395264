import { Box, Grid, LinearProgress, Link, Typography } from "@mui/material";
import { Link as RouteLink } from "react-router-dom";
import { PLAN_TYPE_TO_ICON } from "../constants";
import { toast } from "aagent-ui";
import { useNavigate, useLocation } from "react-router-dom";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import * as types from "../types";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";

type PlanTypeCardPropType = {
  type: types.PlanType;
  description: string;
  onClick?: () => any;
};

function PlanTypeCard({ type, description, onClick }: PlanTypeCardPropType) {
  return (
    <Grid item xs={6} onClick={onClick}>
      <Link
        component={RouteLink}
        to={onClick ? "/add-plan" : `/add-plan/${type}`}
        underline="none"
      >
        <Box
          sx={{
            background: "#FAFAFF",
            border: "1.14039px solid rgba(220, 213, 240, 0.5)",
            boxShadow: "0px 0px 5.70197px rgba(220, 213, 240, 0.5)",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "28px 24px",
            height: "100%",
            minHeight: "180px",
          }}
        >
          <Box sx={{ fontSize: "24px" }}>{PLAN_TYPE_TO_ICON[type]}</Box>
          <Typography variant="h2" gutterBottom>
            {type}
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Link>
    </Grid>
  );
}

function AddPlanView() {
  return (
    <CardWithBottomNavLayout>
      <Typography variant="h1" sx={{ marginBottom: "43px" }}>
        Select plan type
      </Typography>

      <Grid container spacing={2} sx={{ position: "relative" }}>
        <PlanTypeCard
          type={types.PlanType["GUEST"]}
          description="Increase volume with a partial reimbursement"
        />
        <PlanTypeCard
          type={types.PlanType["IMPACT"]}
          description="The most popular plan! Balanced in person and online attraction"
        />
        <PlanTypeCard
          type={types.PlanType["FAME"]}
          description="Pay for the influence only"
        />
        <PlanTypeCard
          onClick={() => toast.error("VIP plan will be available soon.")}
          type={types.PlanType["VIP"]}
          description="Crazy impact from top influencers (available soon)"
        />
      </Grid>
    </CardWithBottomNavLayout>
  );
}

export default function AddPlan() {
  const [paymentMethods, setPaymentMethods] = useState<types.PaymentMethod[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchAPI("/bank/cards")
      .then((response) => response.json())
      .then(setPaymentMethods)
      .then(() => setLoading(false))
      .catch(alert);
  }, []);

  if (loading) {
    return <LinearProgress />;
  }

  if (paymentMethods.length === 0) {
    navigate(`/settings/add-card?redirectUrl=${location.pathname}`);
  }

  return <AddPlanView />;
}
