import { Box, Typography, LinearProgress } from "@mui/material";
import { Button, Card } from "aagent-ui";
import { useEffect, useState } from "react";
import { fetchAPI } from "./authenticator_util";
import AddFundDialog from "./AddFundDialog";
import { useSearchParams } from "react-router-dom";

type MyBalanceProps = {
  credit: number;
  showAddFund: boolean;
  setShowAddFund: (show: boolean) => any;
};

export default function MyBalance({
  credit,
  showAddFund,
  setShowAddFund,
}: MyBalanceProps) {
  return (
    <Card
      sx={{
        padding: "20px",
        borderRadius: "22px",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h3" gutterBottom>
            Available Credit
          </Typography>
          <Typography>${credit}</Typography>
        </Box>
        <Box>
          <Typography variant="h3" gutterBottom>
            Total Credit
          </Typography>
          <Typography>${credit}</Typography>
        </Box>
      </Box>

      <Button
        variant="contained"
        fullWidth
        onClick={() => setShowAddFund(true)}
        sx={{ marginTop: "20px" }}
      >
        Add Fund
      </Button>

      {showAddFund && <AddFundDialog onClose={() => setShowAddFund(false)} />}
    </Card>
  );
}
