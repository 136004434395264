import Header from "./Header";
import * as types from "./types";
import S3Image from "./S3Image";
import { Box, Container, LinearProgress, Typography } from "@mui/material";
import { fetchAPI } from "./authenticator_util";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatUnixTimestamp } from "./time_util";
import { Button, toast } from "aagent-ui";
import { formatReimbursementAmount } from "./ContractUtils";

// interface ContractDetailsProp extends Authenticated {
interface ContractDetailsProp {
  contract: types.Contract;
}

function ContractDetails({ contract }: ContractDetailsProp) {
  return (
    <Box>
      {/* Reimbursement amount */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h2">
            ${formatReimbursementAmount(contract)}
          </Typography>
        </Box>
        <Box>{formatUnixTimestamp(contract.last_updated)}</Box>
      </Box>

      {/* Receipt */}
      <Box sx={{ marginTop: "30px" }}>
        <Typography variant="h6">Receipt</Typography>
        {(contract.receipts || []).map((receipt) => (
          <S3Image
            key={receipt.image_s3_path}
            path={receipt.image_s3_path}
            sx={{ width: "60px", height: "60px" }}
          />
        ))}
      </Box>

      {/* Reviews */}
      {/* <Box sx={{ marginTop: "30px" }}>
        <Typography variant="h6">Public Reviews</Typography>
        {(contract.reviews || [])
          .flatMap((review) => review.image_s3_paths)
          .map((path) => (
            <S3Image
              key={path}
              sx={{ width: "60px", height: "60px" }}
              path={path}
            />
          ))}
      </Box> */}

      {/* Social Medias */}
      <Box sx={{ marginTop: "30px" }}>
        <Typography variant="h6">Social Media</Typography>
        {(contract.social_medias || [])
          .flatMap((media) => media.image_s3_paths)
          .map((path) => (
            <S3Image
              key={path}
              sx={{ width: "60px", height: "60px" }}
              path={path}
            />
          ))}
      </Box>
    </Box>
  );
}

export default function ContractDetailsPage() {
  const { contractId } = useParams();
  const [contract, setContract] = useState<types.Contract>();
  const loading = !contract;
  const navigate = useNavigate();

  useEffect(() => {
    fetchAPI(`/contract/${contractId}`)
      .then((response) => response.json())
      .then(setContract);
  }, [contractId]);

  return (
    <Box>
      <Header />
      <Container sx={{ maxWidth: { xs: "600px" }, paddingBottom: "30px" }}>
        {loading && <LinearProgress />}

        {!loading && (
          <>
            <ContractDetails contract={contract} />
            <Button
              variant="contained"
              onClick={() => navigate(`/contract/${contract.id}/dispute`)}
              sx={{ margin: "20px 0" }}
              fullWidth
            >
              Dispute
            </Button>
          </>
        )}
      </Container>
    </Box>
  );
}
