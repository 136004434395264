import { Box, SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import { Image as ImageIcon } from "akar-icons";
import { theme } from "aagent-ui";
import { Storage } from "aws-amplify";

type S3ImageProps = {
  path: string;
  sx?: SxProps;
};

export default function S3Image({ path, sx }: S3ImageProps) {
  const [src, setSrc] = useState<string>("");
  useEffect(() => {
    Storage.get(path, {
      level: "public",
      download: true,
    }).then((result) => setSrc(URL.createObjectURL(result.Body as Blob)));
  }, [path]);

  if (!src) {
    // Still loading, show an image icon.
    return (
      <Box component={ImageIcon} color={theme.palette.primary.dark} sx={sx} />
    );
  }

  return <Box component="img" src={src} sx={sx} />;
}
