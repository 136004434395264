// Yup TypeScript integration:
// https://github.com/jquense/yup#typescript-integration
//
// Yup enum handling:
// https://github.com/jquense/yup/issues/1013#issuecomment-833886607
//
// assert string is valid enum value
// https://dev.to/mandraketech/typescript-string-enums-the-easy-way-1ke4
// or simpler:
//   const myEnum = val as MyEnumType;
import * as yup from "yup";
import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { ComponentType } from "react";
import { SvgIconProps } from "@mui/material";
export type { IconDefinition };
export type SvgIcon = ComponentType<SvgIconProps>;

export enum PlanType {
  GUEST = "GUEST",
  IMPACT = "IMPACT",
  FAME = "FAME",
  VIP = "VIP",
}

export enum BusinessType {
  RESTAURANT = "RESTAURANT",
  SPA = "SPA",
  SALON = "SALON",
  OTHER = "OTHER",
}

export enum PlanTier {
  ENTRY = "ENTRY",
  STANDARD = "STANDARD",
  PREMIUM = "PREMIUM",
}

export enum ReviewPlatform {
  YELP = "YELP",
  GOOGLE_MAP = "GOOGLE_MAP",
}

export enum SocialMedia {
  TIKTOK = "TIKTOK",
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  YOUTUBE = "YOUTUBE",
  XIAO_HONG_SHU = "XIAO_HONG_SHU",
}

export const userSchema = yup.object({
  username: yup.string().trim().defined(),
  attributes: yup.object({
    email: yup.string().trim().defined().email(),
    phone_number: yup.string().defined(),
    "custom:profile_complete": yup.string().optional(),
  }),
});
export interface User extends yup.InferType<typeof userSchema> {}

export const merchantInfoSchema = yup.object({
  id: yup.string().trim().optional(),
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .defined()
    .ensure()
    .min(2, "First name is at least 2 letters long"),
  last_name: yup
    .string()
    .trim()
    .required("Last name is required")
    .defined()
    .ensure()
    .min(2, "Last name is at least 2 letters long"),
});
export interface MerchantInfo
  extends yup.InferType<typeof merchantInfoSchema> {}

export const addressSchema = yup.object({
  address_line1: yup
    .string()
    .trim()
    .defined()
    .ensure()
    .min(2, "Please enter a valid address"),
  city: yup
    .string()
    .trim()
    .defined()
    .ensure()
    .min(2, "Please enter a valid city"),
  state: yup
    .string()
    .trim()
    .defined()
    .ensure()
    .min(2, "Please enter a valid state"),
  zipcode: yup
    .string()
    .defined()
    .ensure()
    .matches(/^\d{5}(-\d{4})?$/, "Please enter a valid zipcode"),
});
export interface Address extends yup.InferType<typeof addressSchema> {}

export const storeSchema = yup.object({
  id: yup.string().trim().optional(),

  // Obtain from User
  email: yup.string().trim().defined().ensure(),
  phone_number: yup.string().defined().ensure(),
  // Obtain from MerchantInfo
  merchant_id: yup.string().trim().defined().ensure(),
  contact_first_name: yup.string().trim().defined().ensure(),
  contact_last_name: yup.string().trim().defined().ensure(),
  business_name: yup
    .string()
    .trim()
    .defined()
    .ensure()
    .min(2, "Please enter a valid business name"),
  business_type: yup
    .mixed<BusinessType>()
    .oneOf(Object.values(BusinessType))
    .defined()
    .default(BusinessType.RESTAURANT),
  custom_business_type: yup
    .string()
    .optional()
    .default("")
    .when(["business_type"], (business_type, schema) => {
      if (business_type === BusinessType.OTHER) {
        return schema.required("Please specify your custom business type");
      }
      return schema;
    }),
  address: addressSchema.defined(),
  // Mapping:
  //   1: <10
  //   2: 10-30
  //   3: 30-60
  //   4: 60+
  price_range_per_person: yup.number().integer().min(1).max(4).default(2),
});
export interface Store extends yup.InferType<typeof storeSchema> {}

// Schema for the CreatePlanWithType form
export const planFormSchema = yup.object({
  plan_name: yup
    .string()
    .defined()
    .min(2, "Plan name should be at least of length 2"),
  max_contracts_per_week: yup.number().min(0).defined(),
  max_reimbursement_per_bill: yup.number().min(0).defined(),
  social_medias: yup
    .array()
    .of(yup.mixed<SocialMedia>().oneOf(Object.values(SocialMedia)).defined())
    .defined(),
  review_platforms: yup
    .array()
    .of(
      yup.mixed<ReviewPlatform>().oneOf(Object.values(ReviewPlatform)).defined()
    )
    .defined(),
  tiers: yup
    .array()
    .of(yup.mixed<PlanTier>().oneOf(Object.values(PlanTier)).defined())
    .defined()
    .min(1, "Please select at least 1 tier"),
});
export interface PlanForm extends yup.InferType<typeof planFormSchema> {}

//data sent to backend with _ for python
export interface PlanWithoutId {
  merchant_id: string;
  plan_name: string;
  plan_type: PlanType;
  max_contracts_per_week: number;
  max_reimbursement_per_bill: number;
  social_medias: SocialMedia[];
  review_platforms: ReviewPlatform[];
  tiers: PlanTier[];
}

// Plan returned from backend
export interface Plan extends PlanWithoutId {
  id: string;
}

export type Card = {
  brand: string;
  country: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string; // credit
};

export type PaymentMethod = {
  id: string;
  customer: string; // customer id
  billing_details: any;
  created: number; // 1647191119
  card: Card;
};

export type Post = {
  id: string;
  plan_type: PlanType;
  store: Store;
  max_reimbursement_per_bill: number;
};

export type Receipt = {
  image_s3_path: string;
};

export type Review = {
  platform: ReviewPlatform;
  image_s3_paths: string[];
  last_updated: string;
};

export type SocialMediaPost = {
  platform: SocialMedia;
  last_updated: string;
  image_s3_paths: string[];
};

export enum StatusType {
  WORK_IN_PROGRESS = "WORK_IN_PROGRESS",
  WAIT_APPROVAL = "WAIT_APPROVAL",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  GIVE_UP = "GIVE_UP",
}

export type Status = {
  status_type: StatusType;
  timestamp: string;
};

export type Contract = {
  id: string;
  created_timestamp: number;
  merchant_id: string;
  influencer_id: string;
  post: Post;
  subtotal?: number;
  receipts: Receipt[];
  reviews: Review[];
  social_medias: SocialMediaPost[];
  success?: boolean;
  last_updated: number;
  statuses: Status[];
  // tier selected by the influencer
  tier: PlanTier;
};
