import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withAuthenticator } from "./authenticator_util";
import { ScrollToTop, theme } from "aagent-ui";
import "./index.css";
import "aagent-ui/dist/index.css";

import AddPlan from "./pages/AddPlan";
import AllContracts from "./pages/AllContracts";
import ContractDetailsPage from "./ContractDetailsPage";
import CreatePlanWithType from "./pages/CreatePlanWithType";
import MerchantInfo from "./pages/MerchantInfo";
import MoneyTransfer from "./pages/MoneyTransfer";
import Plan from "./pages/Plan";
import Plans from "./pages/Plans";
import Settings from "./pages/Settings";
import BankInfo from "./pages/BankInfo";
import AddCard from "./pages/AddCard";
import Dispute from "./pages/Dispute";
import "./stripeUtils";
import HelpPage from "./pages/HelpPage";

const MERCHANT_USER_TYPE = "MERCHANT";
const AuthenticatedRoutes = withAuthenticator(
  (props: any) => (
    <Routes>
      <Route path="/" element={<Plans {...props} />} />
      <Route path="/plans" element={<Plans {...props} />} />
      <Route path="/plan/:planId" element={<Plan {...props} />} />
      <Route path="/moneytransfer" element={<MoneyTransfer {...props} />} />
      <Route path="/add-plan" element={<AddPlan />} />
      <Route path="/contracts" element={<AllContracts {...props} />} />
      <Route
        path="/contract/:contractId"
        element={<ContractDetailsPage {...props} />}
      />
      <Route
        path="/contract/:contractId/dispute"
        element={<Dispute {...props} />}
      />
      <Route
        path="/add-plan/:planType"
        element={<CreatePlanWithType {...props} />}
      />

      <Route path="/settings" element={<Settings {...props} />} />
      <Route path="/settings/info" element={<MerchantInfo {...props} />} />
      <Route path="/settings/bank-info" element={<BankInfo {...props} />} />
      <Route path="/settings/add-card" element={<AddCard {...props} />} />
      <Route path="/settings/help" element={<HelpPage {...props} />} />
    </Routes>
  ),
  MERCHANT_USER_TYPE
);

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          <AuthenticatedRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
