import * as yup from "yup";
import * as types from "../types";
import StoreForm from "../StoreForm";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useFormik } from "formik";
import { LoadingButton, TextField, toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { Authenticated, fetchAPI } from "../authenticator_util";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../display_util";

const validationSchema = yup
  .object({
    user: types.userSchema.defined(),
    merchant: types.merchantInfoSchema.defined().shape({
      id: yup.ref("$user.username"),
    }),
    store: types.storeSchema.shape({
      merchant_id: yup.ref("$user.username"),
      contact_first_name: yup.ref("$merchant.first_name"),
      contact_last_name: yup.ref("$merchant.last_name"),
      email: yup.ref("$user.attributes.email"),
      phone_number: yup.ref("$user.attributes.phone_number"),
    }),
  })
  .defined();

type MerchantInfoFormProps = {
  onSubmit: any;
  isSubmitting: boolean;
  merchant: types.MerchantInfo;
  user: types.User;
  store: types.Store;
};

function MerchantInfoForm({
  onSubmit,
  isSubmitting,
  merchant,
  user,
  store,
}: MerchantInfoFormProps) {
  const formik = useFormik({
    initialValues: {
      user,
      merchant,
      store,
    },
    validationSchema,
    onSubmit: (rawValues) => {
      const values = validationSchema.cast(rawValues, { context: rawValues });
      onSubmit(values);
    },
  });

  const {
    touched: { merchant: touchedMerchant },
    errors: { merchant: errorsMerchant },
  } = formik;

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* first name */}
        <TextField
          name="merchant.first_name"
          label="First Name"
          variant="outlined"
          size="small"
          sx={{ width: "48%" }}
          margin="normal"
          value={formik.values.merchant.first_name}
          onChange={formik.handleChange}
          error={
            touchedMerchant &&
            touchedMerchant.first_name &&
            errorsMerchant &&
            Boolean(errorsMerchant.first_name)
          }
          helperText={
            touchedMerchant &&
            touchedMerchant.first_name &&
            errorsMerchant &&
            errorsMerchant.first_name
          }
        />

        {/* last name */}
        <TextField
          name="merchant.last_name"
          label="Last Name"
          variant="outlined"
          sx={{ width: "48%" }}
          size="small"
          margin="normal"
          value={formik.values.merchant.last_name}
          onChange={formik.handleChange}
          error={
            touchedMerchant &&
            touchedMerchant.last_name &&
            errorsMerchant &&
            Boolean(errorsMerchant.last_name)
          }
          helperText={
            touchedMerchant &&
            touchedMerchant.last_name &&
            errorsMerchant &&
            errorsMerchant.last_name
          }
        />
      </Box>
      <Box m="10px"></Box>

      {/* Store questions */}
      <Typography
        variant="overline"
        sx={{ fontWeight: "bold", textAlign: "center", color: "primary.light" }}
      >
        STORE
      </Typography>
      <StoreForm
        values={formik.values.store}
        errors={formik.errors.store || {}}
        touched={formik.touched.store || {}}
        prefix="store."
        handleChange={formik.handleChange}
        setFieldValue={formik.setFieldValue}
      />

      <Box m="10px"></Box>
      <Box>
        <LoadingButton
          loading={isSubmitting}
          type="submit"
          fullWidth
          variant="contained"
        >
          Update
        </LoadingButton>
      </Box>
    </Box>
  );
}

interface AuthenticatedWithUpdates extends Authenticated {
  updateAttributes: any;
}

export default function MerchantInfo({
  user,
  updateAttributes,
}: AuthenticatedWithUpdates) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [merchantInfo, setMerchantInfo] = useState<types.MerchantInfo>();
  const [store, setStore] = useState<types.Store>();
  const navigate = useNavigate();

  const handleSubmit = (values: yup.InferType<typeof validationSchema>) => {
    setIsSubmitting(true);

    fetchAPI("/merchant/info-and-store", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const { merchant, store } = await response.json();
        setMerchantInfo(merchant);
        setStore(store);

        let profileComplete = user.attributes["custom:profile_complete"];
        profileComplete = !!profileComplete && JSON.parse(profileComplete);
        if (!profileComplete) {
          updateAttributes({
            "custom:profile_complete": "true",
          }).then(() => navigate("/"));
        } else {
          setIsSubmitting(false);
        }
      })
      .then(() => toast.success("Profile updated"))
      .catch(() => setIsSubmitting(false));
  };

  useEffect(() => {
    fetchAPI("/merchant/info")
      .then((response) => response.json())
      .then(setMerchantInfo)
      .catch(() => setMerchantInfo(types.merchantInfoSchema.getDefault()));

    fetchAPI("/merchant/default-store")
      .then((response) => response.json())
      .then(setStore)
      .catch(() => setStore(types.storeSchema.getDefault()));
  }, []);

  return (
    <CardWithBottomNavLayout>
      <Typography variant="overline">EDIT</Typography>
      <Typography variant="h1" sx={{ marginBottom: "26px" }}>
        Account
      </Typography>

      {/* Email */}
      <TextField
        variant="outlined"
        fullWidth
        label="Email"
        size="small"
        margin="normal"
        value={user.attributes.email}
        disabled
      />

      {/* Phone number */}
      <TextField
        variant="outlined"
        fullWidth
        label="Phone Number"
        size="small"
        margin="normal"
        value={formatPhoneNumber(user.attributes.phone_number)}
        disabled
      />

      {(!merchantInfo || !store) && <LinearProgress />}
      {merchantInfo && store && (
        <MerchantInfoForm
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          user={user}
          merchant={merchantInfo}
          store={store}
        />
      )}
    </CardWithBottomNavLayout>
  );
}
