import { ReactNode } from "react";
import { Box, Chip, SxProps, Typography } from "@mui/material";
import * as types from "./types";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import CreditCardIcon from "./CreditCardIcon";
import { useNavigate } from "react-router-dom";

function IconCard({
  icon,
  children,
  onClick,
  sx,
}: {
  icon: ReactNode;
  children: ReactNode;
  onClick?: any;
  sx?: SxProps;
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        background: "#FFFFFF",
        border: "1px solid rgba(238, 234, 248, 0.75)",
        boxSizing: "border-box",
        borderRadius: "11px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        padding: "14px",
        ...(sx || {}),
      }}
    >
      {icon}
      <Box sx={{ flex: 1, padding: "0 14px" }}>{children}</Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ArrowForwardIosOutlinedIcon
          sx={{
            height: "18px",
            color: "primary.light",
          }}
        />
      </Box>
    </Box>
  );
}

export function AddPaymentMethod() {
  const navigate = useNavigate();
  return (
    <IconCard
      icon={<CreditCardIcon brand="default" />}
      onClick={() => navigate("/settings/add-card?redirectUrl=/?add-fund=1")}
    >
      <Typography
        sx={{
          color: "text.primary",
          fontSize: "15px",
          fontWeight: "bold",
        }}
      >
        Add Card
      </Typography>
    </IconCard>
  );
}

export default function PaymentMethodUI({
  paymentMethod,
  sx,
  onClick,
  isDefault,
}: {
  paymentMethod: types.PaymentMethod;
  sx?: SxProps;
  onClick?: any;
  isDefault: boolean;
}) {
  return (
    <IconCard
      sx={sx}
      icon={<CreditCardIcon brand={paymentMethod.card.brand} />}
      onClick={onClick}
    >
      <Box>
        <Typography
          sx={{
            color: "#000",
            fontSize: "15px",
            marginBottom: "6px",
          }}
        >
          {paymentMethod.card.brand.toUpperCase()}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ color: "text.secondary" }}>
            •••• {paymentMethod.card.last4}
          </Typography>

          {isDefault && (
            <Chip
              sx={{ marginLeft: "12px" }}
              label="default"
              color="success"
              variant="outlined"
              size="small"
            />
          )}
        </Box>
      </Box>
    </IconCard>
  );
}
