import * as types from "./types";

// Impact tier to charge Ratio and Bonus.
const IMPACT_TIER_TO_CRB = {
  [types.PlanTier.ENTRY]: [0.35, 5.0],
  [types.PlanTier.STANDARD]: [0.45, 25.0],
  [types.PlanTier.PREMIUM]: [0.55, 45.0],
};

// Fame tier to charge Ratio and Bonus.
const FAME_TIER_TO_CB = {
  [types.PlanTier.ENTRY]: 20.0,
  [types.PlanTier.STANDARD]: 40.0,
  [types.PlanTier.PREMIUM]: 70.0,
};

function computeChargeNoCap(contract: types.Contract): number {
  const { post, subtotal, tier, success } = contract;
  if (!subtotal) {
    return 0.0;
  }

  // Handle failure case
  if (success === false) {
    if (post.plan_type === types.PlanType.IMPACT) {
      return subtotal * 0.2;
    }
    return 0.0;
  }

  // Handle success case
  switch (post.plan_type) {
    case types.PlanType.GUEST:
      return subtotal * 0.3;
    case types.PlanType.IMPACT:
      const [ratio, bonus] = IMPACT_TIER_TO_CRB[tier];
      return subtotal * ratio + bonus;
    case types.PlanType.FAME:
      return FAME_TIER_TO_CB[tier];
    default:
      return 0.0;
  }
}

function computeCharge(contract: types.Contract): number {
  const { post, subtotal } = contract;
  let charge = computeChargeNoCap(contract);
  const max_reimbursement_per_bill = Number(post.max_reimbursement_per_bill);
  charge = Math.min(charge, max_reimbursement_per_bill);

  // Guard: charge at most 70% of subtotal.
  if (subtotal) {
    charge = Math.min(charge, subtotal * 0.7);
  }
  return charge;
}

// https://stackoverflow.com/a/16233919
// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function formatReimbursementAmount(contract: types.Contract): String {
  if (!contract.subtotal) {
    return "Pending";
  }

  const charge = computeCharge(contract);
  return formatter.format(charge);
}
