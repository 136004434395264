import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  LinearProgress,
} from "@mui/material";
import { Link as RouteLink, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import BottomNavigationLayout from "../BottomNavigationLayout";
import * as types from "../types";
import MyBalance from "../MyBalance";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import WelcomeBanner from "../WelcomeBanner";
import { PLAN_TYPE_TO_ICON } from "../constants";
import { Card } from "aagent-ui";

function PlanCard({ plan }: { plan: types.Plan }) {
  const { id, plan_type, plan_name, max_contracts_per_week } = plan;

  return (
    <Grid item xs={12}>
      <Link component={RouteLink} to={`/plan/${id}`} underline="none">
        <Card
          sx={{
            borderRadius: "24px",
            padding: "14px 17px",

            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginRight: "28px", fontSize: "34px" }}>
            {PLAN_TYPE_TO_ICON[plan_type]}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h3"
              sx={{ fontSize: "13px", marginBottom: "4px" }}
            >
              {plan_type}
            </Typography>
            <Typography
              variant="h2"
              color="secondary.dark"
              sx={{ marginBottom: "4px" }}
            >
              {plan_name || "Untitiled"}
            </Typography>

            <Typography color="grey.300" sx={{ fontSize: "12px" }}>
              Limit: {max_contracts_per_week} contracts / week
            </Typography>
          </Box>

          <ArrowForwardIosOutlinedIcon
            sx={{ height: "20px", color: "primary.light" }}
          />
        </Card>
      </Link>
    </Grid>
  );
}

function AddPlanCard() {
  return (
    <Grid item xs={12}>
      <Link component={RouteLink} to={`/add-plan`} underline="none">
        <Card
          sx={{
            borderRadius: "24px",
            padding: "14px 17px",

            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginRight: "28px", fontSize: "34px" }}>
            <span>&#10133;</span>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h3" sx={{ fontSize: "13px" }}></Typography>
            <Typography variant="h2" sx={{ marginBottom: "4px" }}>
              Add new plan
            </Typography>

            <Typography
              color="secondary.main"
              sx={{ fontSize: "13px" }}
            ></Typography>
          </Box>

          <ArrowForwardIosOutlinedIcon
            sx={{ height: "20px", color: "primary.light" }}
          />
        </Card>
      </Link>
    </Grid>
  );
}

type PlansViewProps = {
  plans: types.Plan[];
  credit: number;
  showAddFund: boolean;
  setShowAddFund: (show: boolean) => any;
  merchantInfo: types.MerchantInfo;
};

function PlansView({
  plans,
  credit,
  showAddFund,
  setShowAddFund,
  merchantInfo,
}: PlansViewProps) {
  return (
    <Container sx={{ padding: "24px 16px" }}>
      <WelcomeBanner merchantInfo={merchantInfo} />
      <MyBalance
        credit={credit}
        showAddFund={showAddFund}
        setShowAddFund={setShowAddFund}
      />

      {plans.length > 0 && (
        <Typography
          variant="overline"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "primary.light",
            display: "block",
            margin: "20px 0",
          }}
        >
          YOUR ACTIVE PLANS
        </Typography>
      )}

      <Grid container rowSpacing={2}>
        {plans.map((plan) => (
          <PlanCard key={plan.id} plan={plan} />
        ))}

        {/* Add plan */}
        <AddPlanCard />
      </Grid>
    </Container>
  );
}

export default function Plans() {
  const [plans, setPlans] = useState<types.Plan[]>([]);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [credit, setCredit] = useState<number>(0);
  const [loadingFund, setLoadingFund] = useState<boolean>(true);
  const [merchantInfo, setMerchantInfo] = useState<types.MerchantInfo>();
  const loadingMerchantInfo = !merchantInfo;

  const [searchParams, setSearchParams] = useSearchParams();
  const showAddFund = !!searchParams.get("add-fund");
  const setShowAddFund = (shouldShow: boolean) =>
    setSearchParams(shouldShow ? { "add-fund": "1" } : {});

  useEffect(() => {
    setLoadingFund(true);
    fetchAPI("/bank/credit")
      .then((response) => response.json())
      .then((data) => {
        setCredit(data.credit / 100);
        setLoadingFund(false);
      });
  }, [showAddFund]);

  useEffect(() => {
    fetchAPI(`/merchant/plans`)
      .then((response) => response.json())
      .then((plans) => {
        setPlans(plans);
        setLoadingPlans(false);
      });
  }, []);

  useEffect(() => {
    fetchAPI("/merchant/info")
      .then((response) => response.json())
      .then(setMerchantInfo);
  }, []);

  const loading = loadingPlans || loadingFund || loadingMerchantInfo;

  return (
    <BottomNavigationLayout>
      {loading && <LinearProgress />}
      {!loading && (
        <PlansView
          plans={plans}
          credit={credit}
          showAddFund={showAddFund}
          setShowAddFund={setShowAddFund}
          merchantInfo={merchantInfo}
        />
      )}
    </BottomNavigationLayout>
  );
}
