import { withAuthenticator as withAuthenticatorOrigin } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";
import { AMPLIFY_CONFIG, API_DOMAIN } from "./constants";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth } from "aws-amplify";
import RequireInfo from "./RequireInfo";
import { User } from "./types";
Amplify.configure(AMPLIFY_CONFIG);

export interface Authenticated {
  signOut: () => any;
  user: User;
  updateAttributes: (attributes: any) => void;
}

export function withAuthenticator(component: any, userType: string) {
  // Instruction to add "user_type" field in sign up:
  // https://ui.docs.amplify.aws/components/authenticator?platform=react#sign-up-fields
  const options = {
    className: "gradient-background",
    components: {
      // SignUp: {
      //   FormFields() {
      //     // const { validationErrors } = useAuthenticator();
      //     return (
      //       <>
      //         {/* Re-use default `Authenticator.SignUp.FormFields` */}
      //         <Authenticator.SignUp.FormFields />
      //       </>
      //     );
      //   },
      // },
    },
    services: {
      async handleSignUp(formData: any) {
        const { attributes, ...rest } = formData;
        return Auth.signUp({
          ...rest,
          attributes: {
            ...attributes,
            "custom:user_type": userType,
            "custom:profile_complete": "false",
          },
        });
      },
    },
  };

  return withAuthenticatorOrigin(RequireInfo(component), options);
}

export async function fetchAPI(path: string, init: any = undefined) {
  const url = API_DOMAIN + path;

  // You may have saved off the JWT somewhere when the user logged in.
  // If not, get the token from aws-amplify:
  const user = await Auth.currentAuthenticatedUser();

  // See here for difference between id token and access token:
  // https://auth0.com/blog/id-token-access-token-what-is-the-difference/
  // const accessToken = user.signInUserSession.accessToken.jwtToken;
  const idToken = user.signInUserSession.idToken.jwtToken;

  let request = init || {};
  request.headers = {
    ...(request.headers || {}),
    Authorization: `Bearer ${idToken}`,
  };

  return fetch(url, request).then(async (response) => {
    if (!response.ok) {
      const text = await response.text();
      throw Error(`${response.statusText}: ${text}`);
    }
    return response;
  });
}

export async function getCurrentUser() {
  return Auth.currentAuthenticatedUser();
}
