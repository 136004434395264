import { Box, Container, Typography } from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import { Authenticated, fetchAPI } from "../authenticator_util";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import SliderField from "../SliderField";
import {
  TextField,
  LoadingButton,
  MultiSelect,
  SelectOption,
  getSelectedNames,
} from "aagent-ui";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import * as types from "../types";
import CreatePlanHelpDialog from "../CreatePlanHelpDialog";
import AcceptedSocialMedias from "../AcceptedSocialMedias";
import { PUBLIC_SOCIAL_MEDIAS } from "../constants";

function getTierOptions(selectedTiers: types.PlanTier[]): SelectOption[] {
  return Object.values(types.PlanTier).map((tier) => ({
    name: tier,
    label: tier,
    selected: selectedTiers.includes(tier),
  }));
}

const PLAN_TYPE_TO_DEFAULT_FORM_VALUES = {
  [types.PlanType.GUEST]: {
    max_contracts_per_week: 20,
    max_reimbursement_per_bill: 35,
    reimbursement_min: 20,
    reimbursement_max: 100,
  },
  [types.PlanType.IMPACT]: {
    max_contracts_per_week: 15,
    max_reimbursement_per_bill: 35,
    reimbursement_min: 20,
    reimbursement_max: 150,
  },
  [types.PlanType.FAME]: {
    max_contracts_per_week: 15,
    max_reimbursement_per_bill: 100,
    reimbursement_min: 100,
    reimbursement_max: 100,
  },
  [types.PlanType.VIP]: {
    max_contracts_per_week: 15,
    max_reimbursement_per_bill: 1000,
    reimbursement_min: 1000,
    reimbursement_max: 1000,
  },
};

export default function BudgetPlan({ user }: Authenticated) {
  const { planType: plan_type } = useParams() as { planType: types.PlanType };
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formDefaultsForPlanType = PLAN_TYPE_TO_DEFAULT_FORM_VALUES[plan_type];
  const initialValues: types.PlanForm = {
    plan_name: `My ${plan_type} Plan`,
    max_contracts_per_week: formDefaultsForPlanType.max_contracts_per_week,
    max_reimbursement_per_bill:
      formDefaultsForPlanType.max_reimbursement_per_bill,
    social_medias: PUBLIC_SOCIAL_MEDIAS,
    review_platforms: Object.values(types.ReviewPlatform),
    tiers: [types.PlanTier.ENTRY, types.PlanTier.STANDARD],
  };

  const submitPlan = (rawValues: types.PlanForm) => {
    const values = {
      ...types.planFormSchema.cast(rawValues),
      merchant_id: user.username,
      plan_type: types.PlanType[plan_type],
    };

    fetchAPI("/plan", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((plan) => {
        setIsSubmitting(false);
        navigate(`/plan/${plan.id}`);
      });

    setIsSubmitting(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: types.planFormSchema,
    onSubmit: submitPlan,
  });

  const { values, setFieldValue } = formik;
  const setTierOptions = (options: SelectOption[]) => {
    setFieldValue("tiers", getSelectedNames(options));
  };
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  return (
    <CardWithBottomNavLayout>
      <Typography variant="overline" sx={{ color: "primary.light" }}>
        PLAN DETAILS
      </Typography>
      <Typography
        variant="h1"
        sx={{ color: "secondary.main", marginBottom: "32px" }}
      >
        {plan_type}
      </Typography>

      <FormikProvider value={formik}>
        <Container
          component="form"
          noValidate
          sx={{ maxWidth: { xs: "600px" } }}
          onSubmit={formik.handleSubmit}
        >
          {/* plan name */}
          <TextField
            fullWidth
            label="Plan name"
            name="plan_name"
            value={values.plan_name}
            variant="outlined"
            onChange={formik.handleChange}
            size="small"
          />
          <Box sx={{ m: "20px" }}></Box>

          {/* max_contracts_per_week */}
          <SliderField
            label="Max contracts per week"
            name="max_contracts_per_week"
            value={values.max_contracts_per_week}
            setFieldValue={setFieldValue}
            step={1}
            min={0}
            max={100}
          />

          <Box sx={{ m: "20px" }}></Box>

          {/* max_reimbursement_per_bill */}
          {[types.PlanType.GUEST, types.PlanType.IMPACT].includes(
            plan_type
          ) && (
            <SliderField
              label="Max Reimbursement / Bill"
              name="max_reimbursement_per_bill"
              value={values.max_reimbursement_per_bill}
              setFieldValue={setFieldValue}
              step={5}
              min={formDefaultsForPlanType.reimbursement_min}
              max={formDefaultsForPlanType.reimbursement_max}
            />
          )}

          <Box sx={{ m: "20px" }}></Box>

          {/* Select pricing tier */}
          {plan_type !== types.PlanType.GUEST && (
            <MultiSelect
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Influencer Tier
                  <HelpOutlineIcon
                    onClick={() => setOpenHelpDialog(true)}
                    sx={{ fontSize: "16px", marginLeft: "6px" }}
                  />
                </Box>
              }
              options={getTierOptions(values.tiers)}
              onSelect={setTierOptions}
              error={formik.errors.tiers}
            />
          )}
          <Box sx={{ m: "26px" }} />

          {plan_type !== types.PlanType.GUEST && (
            <AcceptedSocialMedias
              value={formik.values.social_medias}
              onChange={(medias) =>
                formik.setFieldValue("social_medias", medias)
              }
            />
          )}

          <Box sx={{ m: "40px" }}></Box>
          <Box>
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              fullWidth
            >
              Create Plan
            </LoadingButton>
          </Box>

          <Box sx={{ m: "30px" }}></Box>
        </Container>
      </FormikProvider>

      <CreatePlanHelpDialog
        open={openHelpDialog}
        onClose={() => setOpenHelpDialog(false)}
        planType={plan_type}
      />
    </CardWithBottomNavLayout>
  );
}
