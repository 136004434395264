import { Container, Snackbar, Alert } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAPI } from "../authenticator_util";
import PlanPageHeader from "../PlanPageHeader";
import PlanSummary from "../PlanSummary";
import PlanContracts from "../PlanContracts";
import BottomNavigationLayout from "../BottomNavigationLayout";

type TabPanelProps = {
  tab: number;
  index: number;
  children?: ReactNode;
};

function TabPanel({ tab, index, children }: TabPanelProps) {
  return <Container>{tab === index && children}</Container>;
}

export default function Plan() {
  const { planId } = useParams();
  const [plan, setPlan] = useState<any>(null);
  const [tab, setTab] = useState(0);
  const [updateSnackbarOpen, setUpdateSnackbarOpen] = useState(false);
  useEffect(() => {
    fetchAPI(`/plan/${planId}`)
      .then((response) => response.json())
      .then(setPlan);
  }, [planId]);

  return (
    <BottomNavigationLayout>
      <PlanPageHeader
        tab={tab}
        onChange={(_: any, newTab: number) => setTab(newTab)}
      />
      <TabPanel tab={tab} index={0}>
        {plan && <PlanContracts plan={plan} />}
      </TabPanel>
      <TabPanel tab={tab} index={1}>
        {plan && (
          <PlanSummary
            plan={plan}
            setPlan={(plan: any) => {
              setPlan(plan);
              setUpdateSnackbarOpen(true);
            }}
          />
        )}
      </TabPanel>

      <Snackbar
        open={updateSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setUpdateSnackbarOpen(false)}
      >
        <Alert onClose={() => setUpdateSnackbarOpen(false)} severity="success">
          Plan successfully updated!
        </Alert>
      </Snackbar>
    </BottomNavigationLayout>
  );
}
