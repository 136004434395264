import { Card, TextField, toast } from "aagent-ui";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { fetchAPI } from "./authenticator_util";
import { Box, LinearProgress, Typography, InputAdornment } from "@mui/material";
import { LoadingButton } from "aagent-ui";
import { useNavigate } from "react-router-dom";
import SlideUpDialog from "./SlideUpDialog";
import PaymentMethodUI, { AddPaymentMethod } from "./PaymentMethodUI";
import * as yup from "yup";
import * as types from "./types";

const validationSchema = yup.object({
  amount: yup.number().required("Amount is required"),
});

function CashChoice({
  amount,
  selected,
  onClick,
}: {
  amount?: number;
  selected?: boolean;
  onClick: any;
}) {
  return (
    <Card
      sx={{
        backgroundColor: selected ? "primary.main" : "#FFFFFF",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "12px 22px",
        width: "23%",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          color: selected ? "#fff" : "primary.dark",
          fontWeight: "bold",
          fontSize: "13px",
        }}
      >
        {amount ? `$${amount}` : "OTHER"}
      </Typography>
    </Card>
  );
}

export default function AddFund({ onClose }: { onClose: any }) {
  const [paymentMethod, setPaymentMethod] = useState<
    types.PaymentMethod | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const textInput = useRef<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAPI("/bank/payment-methods-and-default")
      .then((response) => response.json())
      .then(
        ({
          payment_methods: paymentMethods,
          default_payment_method_id: defaultPaymentMethodId,
        }) => {
          if (paymentMethods.length > 0) {
            let defaultIndex = 0;
            paymentMethods.forEach(
              (method: types.PaymentMethod, index: number) => {
                if (method.id === defaultPaymentMethodId) {
                  defaultIndex = index;
                }
              }
            );
            setPaymentMethod(paymentMethods[defaultIndex]);
          }

          setLoading(false);
        }
      )
      .catch(toast.error);
  }, []);

  const initialValues = { amount: 100 };
  const onSubmit = (rawValues: typeof initialValues) => {
    if (loading || !paymentMethod) {
      toast.error("Payment method not ready");
      return;
    }

    setIsSubmitting(true);
    const values = {
      ...validationSchema.cast(rawValues),
      payment_method_id: paymentMethod.id,
    };
    fetchAPI("/bank/add-fund", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "text/plain",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.text())
      .then(() => setIsSubmitting(false))
      .then(onClose) // Close the dialog on success
      .catch((error) => {
        setIsSubmitting(false);
        toast.error({ title: error, autotimeout: 5000 });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  if (loading) {
    return (
      <SlideUpDialog onClose={onClose}>
        <LinearProgress />
      </SlideUpDialog>
    );
  }

  return (
    <SlideUpDialog onClose={onClose}>
      <Typography
        variant="overline"
        sx={{
          color: "primary.light",
          textAlign: "center",
          fontWeight: "bold",
          marginBottom: "28px",
        }}
      >
        ADD FUNDS
      </Typography>

      {/* Credit card choice */}
      {paymentMethod ? (
        <PaymentMethodUI
          isDefault
          paymentMethod={paymentMethod}
          onClick={() => navigate("/settings/bank-info")}
        />
      ) : (
        <AddPaymentMethod />
      )}

      <form onSubmit={formik.handleSubmit}>
        {/* Amount choice */}
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CashChoice
            amount={100}
            onClick={() => formik.setFieldValue("amount", 100)}
            selected={formik.values.amount === 100}
          />
          <CashChoice
            amount={300}
            onClick={() => formik.setFieldValue("amount", 300)}
            selected={formik.values.amount === 300}
          />
          <CashChoice
            amount={1000}
            onClick={() => formik.setFieldValue("amount", 1000)}
            selected={formik.values.amount === 1000}
          />
          <CashChoice
            onClick={() => {
              formik
                .setFieldValue("amount", 500)
                .then(
                  () => textInput.current != null && textInput.current.focus()
                );
            }}
            selected={![100, 300, 1000].includes(formik.values.amount)}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: "16px",
            borderBottom: "1px solid",
            borderBottomColor: "primary.light",
          }}
        >
          <Typography>Amount</Typography>
          <TextField
            variant="standard"
            name="amount"
            onChange={formik.handleChange}
            value={formik.values.amount}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              disableUnderline: true,
            }}
            sx={{
              width: "80px",
            }}
            autoFocus={![100, 300, 1000].includes(formik.values.amount)}
            inputRef={textInput}
          />
        </Box>

        <LoadingButton
          variant="contained"
          type="submit"
          fullWidth
          loading={isSubmitting}
          disabled={!paymentMethod}
          sx={{ marginTop: "30px" }}
        >
          Add Fund
        </LoadingButton>
      </form>
    </SlideUpDialog>
  );
}
