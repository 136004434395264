import { Typography, LinearProgress } from "@mui/material";
import { Button, toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import { useNavigate } from "react-router-dom";
import { PaymentMethod } from "../types";
import PaymentMethodUI from "../PaymentMethodUI";

export default function BankInfo() {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<
    string | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAPI("/bank/payment-methods-and-default")
      .then((response) => response.json())
      .then(({ payment_methods, default_payment_method_id }) => {
        setPaymentMethods(payment_methods);
        setDefaultPaymentMethodId(default_payment_method_id);
        setLoading(false);
      })
      .catch(alert);
  }, []);

  const updateDefaultPaymentMethod = (method: PaymentMethod) => {
    if (method.id === defaultPaymentMethodId) {
      return;
    }

    fetchAPI("/bank/update-default-payment-method-id", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({ id: method.id }),
    })
      .then(() => setDefaultPaymentMethodId(method.id))
      .catch(toast.error);
  };

  return (
    <CardWithBottomNavLayout closeLinkTo="/settings">
      <Typography variant="overline">Manage</Typography>
      <Typography variant="h1" sx={{ marginBottom: "32px" }}>
        Payments
      </Typography>

      {loading && <LinearProgress />}

      {paymentMethods.length > 0 && (
        <Typography variant="h2" sx={{ color: "primary.dark" }} gutterBottom>
          Saved Payment Methods
        </Typography>
      )}

      {paymentMethods.map((method) => (
        <PaymentMethodUI
          sx={{ marginY: "20px", cursor: "pointer" }}
          key={method.id}
          isDefault={method.id === defaultPaymentMethodId}
          paymentMethod={method}
          onClick={() => updateDefaultPaymentMethod(method)}
        />
      ))}

      <Button
        variant="contained"
        fullWidth
        onClick={() =>
          navigate("/settings/add-card?redirectUrl=/settings/bank-info")
        }
        sx={{ marginTop: "30px" }}
      >
        Add new card
      </Button>
    </CardWithBottomNavLayout>
  );
}
