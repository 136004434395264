import { Box, Container, Typography, Divider, SxProps } from "@mui/material";
import { Card, TextField, LoadingButton } from "aagent-ui";
import { ReactNode, useState } from "react";
import { fetchAPI } from "./authenticator_util";
import { useFormik, FormikProvider } from "formik";
import SliderField from "./SliderField";
import { SOCIAL_MEDIA_TO_ICON } from "./constants";
import MediaIcon from "./MediaIcon";
import * as types from "./types";

interface PlanSummaryProps {
  plan: types.Plan;
  setPlan: (plan: types.Plan) => void;
}

function AcceptedSocialMediaRow({
  social_medias,
  sx,
}: {
  social_medias: types.SocialMedia[];
  sx?: SxProps;
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", ...(sx || {}) }}>
      {social_medias.map((media, index) => (
        <MediaIcon
          icon={SOCIAL_MEDIA_TO_ICON[media]}
          selected
          key={index}
          sx={{ margin: "auto 5px" }}
        />
      ))}
    </Box>
  );
}

function TableRow({ left, right }: { left: ReactNode; right: ReactNode }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "16px 0",
        }}
      >
        <Typography variant="h3" sx={{ color: "primary.dark" }}>
          {left}
        </Typography>
        <Typography
          variant="body1"
          component={Box}
          sx={{ color: "primary.dark" }}
        >
          {right}
        </Typography>
      </Box>
      <Divider sx={{ borderColor: "secondary.light" }} />
    </>
  );
}

export default function PlanSummary({ plan, setPlan }: PlanSummaryProps) {
  const [isUpdating, setIsUpdating] = useState(false);

  const initialValues = {
    plan_name: plan.plan_name,
    max_contracts_per_week: plan.max_contracts_per_week,
  };

  const updatePlan = (values: typeof initialValues) => {
    if (
      values.plan_name === plan.plan_name &&
      values.max_contracts_per_week === plan.max_contracts_per_week
    )
      return;
    setIsUpdating(true);

    fetchAPI(`/update-plan/${plan.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }).then(() => {
      setIsUpdating(false);
      setPlan({ ...plan, ...values });
    });
  };
  const formik = useFormik({
    initialValues,
    onSubmit: updatePlan,
  });
  const { values, setFieldValue } = formik;
  return (
    <FormikProvider value={formik}>
      <Container
        component="form"
        noValidate
        sx={{ maxWidth: { xs: "600px" } }}
        onSubmit={formik.handleSubmit}
      >
        <Card sx={{ padding: "30px 26px" }}>
          {/* name */}
          <TextField
            label="Name"
            name="plan_name"
            variant="outlined"
            fullWidth
            value={values.plan_name}
            onChange={formik.handleChange}
          />
          <Box sx={{ m: "10px" }}></Box>
          <SliderField
            label="Max contracts per week"
            name="max_contracts_per_week"
            value={values.max_contracts_per_week}
            setFieldValue={setFieldValue}
            step={1}
            min={0}
            max={50}
          />
          <Box sx={{ m: "40px" }}></Box>
          {/* Table of immutable content */}
          <Box>
            <TableRow left="PLAN TYPE" right={plan.plan_type} />
            {/* max_contracts_per_week */}

            <TableRow
              left="MAXIMUM REIMBURSEMENT"
              right={plan.max_reimbursement_per_bill}
            />

            {plan.tiers && plan.plan_type !== types.PlanType.GUEST && (
              <TableRow left="Tiers" right={plan.tiers.join(", ")} />
            )}

            {plan.plan_type !== types.PlanType.GUEST && (
              <TableRow
                left="SOCIAL MEDIA"
                right={
                  <AcceptedSocialMediaRow social_medias={plan.social_medias} />
                }
              />
            )}
          </Box>

          <Box sx={{ m: "36px" }}></Box>
          <LoadingButton
            loading={isUpdating}
            fullWidth
            variant="contained"
            type="submit"
            disabled={
              values.plan_name === plan.plan_name &&
              values.max_contracts_per_week === plan.max_contracts_per_week
            }
          >
            Update
          </LoadingButton>
        </Card>
      </Container>
    </FormikProvider>
  );
}
