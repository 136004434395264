import { Slide, Dialog } from "@mui/material";
import React, { ReactNode } from "react";
import { TransitionProps } from "@mui/material/transitions";

const SlideUpTransition = React.forwardRef(function SlideUpTransition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SlideUpDialog({
  onClose,
  children,
}: {
  onClose: any;
  children: ReactNode;
}) {
  return (
    <Dialog
      open={true}
      TransitionComponent={SlideUpTransition}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paperScrollPaper": {
          position: "absolute",
          bottom: 0,
          margin: 0,
          width: "100%",
          padding: "32px 24px",
          borderTopRightRadius: "24px",
          borderTopLeftRadius: "24px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: "#fbfbff",
        },
      }}
    >
      {children}
    </Dialog>
  );
}
